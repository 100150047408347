import React, {useEffect, useState} from 'react';
import './Grid.css';

const Square = ({ value, onClick, onMouseOver }) => {
    return (
        <div
            className={`square ${value === '1' ? 'filled' : ''}`}
            onClick={onClick}
            onMouseOver={onMouseOver}
        ></div>
    );
};

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getRandomGrid = () => {
    // const startWith = 1;
    let initialGrid = [];

    for (let i=1;i<=256;i++) {
        // const next = 1;
        const next = Math.floor(Math.random() * (1 - 0 + 1)) + 0;
        // const next = i === 0 ? startWith : (i % 2 === 0 ? startWith : startWith===1?0:1);
        initialGrid = [next, ...initialGrid]
    }

    return initialGrid;
};

const fillGridByPercents = percents => {
    let initialGrid = [];

    for (let i=1;i<=256;i++) {
        const next = Math.random() < (percents / 100) ? '1' : '0';
        initialGrid = [next, ...initialGrid]
    }

    return initialGrid;
};

const str=' !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~\n'

const arr = str.split('')
const bins=[]
for (let i=0;i<arr.length;i++) {
    const curr=arr[i]
    const bin = curr.charCodeAt().toString(2).padStart(8, '0')

    bins.push(bin)
}

const binsFull=[]
for (let i=0;i<bins.length;i++) {
    const curr=bins[i]

    let output=''
    for (let i=1;i<=32;i++) {
        output+=curr

    }
    binsFull.push(output)
}

const getEmptyGrid = () => Array(256).fill('0')

const Grid = () => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [grid, setGrid] = useState(getEmptyGrid());
    const [wallets, setWallets] = useState([]);
    const [balances, setBalances] = useState({});
    const [percents, setPercents] = useState(50);
    const [minPercents, setMinPercents] = useState(70);
    const [maxPercents, setMaxPercents] = useState(80);
    const [index, setIndex] = useState(0);
    const [token, setToken] = useState('');
    const [useBinsFull, setUseBinsFull] = useState(false);

    // console.log(grid)

    useEffect(() => {
        const bits = grid.join('');
        if (!bits.includes('1')) {
            return;
        }
        fetchAddr(bits, token)
            .then(r => {
                if (r.txs>0) {
                    alert(`txs=${r.txs}, confirmed=${r.confirmed}, ${r.address}, ${r.hex}`);
                }

                setWallets([r, ...wallets]);

                balances[r.address] = r;
                setBalances(balances);

            }).catch(console.error);
    // eslint-disable-next-line
    }, [grid]);

    useEffect(() => {
        if (!useBinsFull) {
            return
        }
        setGrid(binsFull[index].split(''))
    }, [index, useBinsFull]);

    const handleClick = index => {
        const newGrid = [...grid];
        newGrid[index] = newGrid[index] === '1' ? '0' : '1';
        setGrid(newGrid);
    };

    const gridValues = grid.join('');

    const handleMouseOver = index => {
        if (isMouseDown) {
            handleClick(index);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    return (
        <div
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp} // To handle mouse release outside the grid
        >
            <div>
                <button
                    onClick={() => setGrid(getEmptyGrid())}
                >Reset
                </button>
            </div>

            <div>
                <input type="number" min="0" max={binsFull.length - 1} value={index} onChange={e => {
                    setIndex(e.target.value)}} />
                <button disabled={index===binsFull.length-1} onClick={() => setIndex(index+1)}>+</button>
                <button disabled={index===0} onClick={() => setIndex(index-1)}>-</button>
                <input type="checkbox" checked={useBinsFull} onChange={e => {
                    setUseBinsFull(!useBinsFull);
                    if (e.target.checked) {
                        setGrid(binsFull[index].split(''))
                    } else {
                        setGrid(getEmptyGrid())
                    }
                }} /> Use bins full
            </div>

            <button onClick={() => setGrid(getRandomGrid())}>Rand</button>
            <input
                type="number"
                min="1"
                max="100"
                value={percents}
                onChange={e => setPercents(e.target.value)}
            />
            <button
                onClick={() => setGrid(fillGridByPercents(percents))}
            >Fill</button>

            <div>
                <input
                    type="number"
                    min="1"
                    max="100"
                    value={minPercents}
                    onChange={e => setMinPercents(e.target.value)}
                />
                <input
                    type="number"
                    min="1"
                    max="100"
                    value={maxPercents}
                    onChange={e => setMaxPercents(e.target.value)}
                />
                <button
                    onClick={() => setGrid(fillGridByPercents(getRandomInt(minPercents, maxPercents)))}
                >Rand between</button>
            </div>

            <div className="grid">
                {grid.map((value, index) => (
                    <Square
                        key={index}
                        value={value}
                        onClick={() => handleClick(index)}
                        onMouseOver={() => handleMouseOver(index)}
                    />
                ))}
            </div>
            <div className="gridValues">{gridValues}</div>

            <table>
                {/*<thead>*/}
                {/*    <tr>*/}
                {/*        <th>address</th>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th>balance</th>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th>hex</th>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th>bits</th>*/}
                {/*    </tr>*/}
                {/*</thead>*/}
                <tbody>
                {wallets.map((w, index) => (
                    <tr key={index}>
                        <td>
                            <h3>{w.address}</h3>
                        </td>
                        <td>
                            {balances[w.address] !== undefined &&
                                <div>
                                    <div>txs: {balances[w.address].txs}</div>
                                    <div>confirmed: {balances[w.address].confirmed}</div>
                                    {/*<div>unconfirmed: {balances[w.address].unconfirmed}</div>*/}
                                    {/*<div>utxo: {balances[w.address].utxo}</div>*/}
                                    <div>received: {balances[w.address].received}</div>
                                </div>}
                        </td>
                        <td>{w.hex}</td>
                        <td>{w.bits}</td>
                    </tr>
                ))}
                </tbody>

            </table>
            <input type="text" value={token} onChange={e => setToken(e.target.value)} />
        </div>
    );
};

const fetchAddr = (bits, token) => {
    var requestOptions = {
        method: 'GET',
    };

    const addressBaseUrl = process.env.REACT_APP_ADDRESS_BASE_URL;

    return fetch(`${addressBaseUrl}/?bits=${bits}&token=${token}`, requestOptions)
        .then(response => response.json())
        .catch(console.error);
};

// const fetchBalance = addr => {
//     const balanceBaseUrl = process.env.REACT_APP_BALANCE_BASE_URL;
//
//     // return fetch(`https://blockchain.info/q/addressbalance/${addr}`)
//     return fetch(`${balanceBaseUrl}/${addr}`)
//         .then(response => response.json())
//         .catch(console.error);
// };

export default Grid;
